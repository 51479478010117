import { faSearch } from '@fortawesome/free-solid-svg-icons';
import React from 'react'
import { Button, Card, Col, Container, Form, Image, ListGroup, Row, Stack } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getAuthData } from '../util/auth';
import { useState } from 'react';
import { useEffect } from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import '@fortawesome/fontawesome-free/css/all.css';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function EvaluateQuestions() {
    const navigate = useNavigate();
    const [inputValues, setInputValues] = useState([]);
  const params = useParams();
  console.log(params);
  const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [fetchProgress, setFetchProgress] = useState(0);
    // const [filteredData, setFilteredData] = useState(null);
    const [filteredData, setFilteredData] = useState([]);

    const [isAnswer, setIsAnswer] = useState(0);

  const nest_url = process.env.REACT_APP_NEST_URL;
    const url = nest_url + "public/me/evaluate_participant_detail";
    const secretCode = process.env.REACT_APP_SECRET_CODE;

    const token = getAuthData();

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    useEffect(() => {
        setIsFetching(true);
        setFetchProgress(0);
        const fetchData = async () => {
        try {

            const response = await fetch(
            url,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify({ 
                    'courseId': params.courseId,
                    'feedbackId': params.feedbackId,
                    'userid': params.userid,
                }),
            }
            );

            if (!response.ok) {
            throw new Error('Could not fetch data');
            }

            const responseBody = await response.json();

            setData(responseBody.data);
            setIsAnswer(responseBody.data.is_answer);
            setFetchProgress(100);
            setIsFetching(false);
        } catch (error) {
            setError(error);
            // window.location.href = '/auth';
        }
        };

        fetchData();
    }, []);

    const handleInputChange = (questionId, value) => {
        setInputValues((prevValues) => {
          // Check if the questionId exists in the array
          const existingIndex = prevValues.findIndex(
            (item) => item.questionId === questionId
          );
      
          if (existingIndex !== -1) {
            // If the questionId exists, update the answer value
            prevValues[existingIndex].answer = value;
          } else {
            // If the questionId doesn't exist, add a new object to the array
            prevValues.push({
              empCode: data.employee[0].empCode,
              userid: params.userid,
              courseId: params.courseId,
              questionId: questionId,
              answer: value,
            });
          }
      
          return [...prevValues];
        });
    };

    const handleSubmit = async (e) => {
        const jsonText = JSON.stringify(inputValues);
        console.log(jsonText);
        e.preventDefault();

        try {
    
            const response = await fetch(
              nest_url + "public/feedback/submit_feedback_answer",
              {
                method: "POST",
                headers: headers,
                body: JSON.stringify({ 
                    'data': jsonText,
                }),
              }
            );
    
            if (!response.ok) {
              throw new Error('Could not fetch data');
            }
    
            const responseBody = await response.json();
            console.log(responseBody);
            if(responseBody.success){
                Swal.fire('Evaluation submitted successfully!').then(() => {
                    navigate(-1);
                });
            }
          } catch (error) {
            setError(error);
            window.location.href = '/auth';
          }

     };

  return (
    <>
            <Container fluid className="p-0 " >
                <Row className="">
                    <div className="d-flex justify-content-start flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mr-3">
                        <Link to={`/evaluate`} style={{ textDecoration: 'none' }}>
                            <h6 >Evaluate</h6>
                        </Link>
                        <h6> &nbsp; &gt; &nbsp; </h6>
                                
                        <Link to={`/evaluate/${params.courseId}/${params.subCategoryId}/${params.feedbackId}`} style={{ textDecoration: 'none' }}>
                            <h6>{data && data.course[0].fullname}</h6>
                        </Link>
                        <h6> &nbsp; &gt; &nbsp; </h6>
                        <h6>{data && data.employee[0].empCode}</h6>
                    </div>
                </Row>

                <Row className="mt-2 mt-lg-2">
                    <Col lg={12}>
                        {data !== null ? (
                            <Card className="mb-4">
                                <Card.Body className="card-today-schedule">

                                    <Row className="p-2 ">
                                        <Col className=" ">
                                            <Row className="p-2 header-participant-evaluate-list">
                                                            <Col lg={2} md={2} className=" p-2 d-flex justify-content-center">
                                                                <Image className="evaluate-participant-detail" src={data && data.employee[0].empPhoto} roundedCircle />
                                                            </Col>
                                                            <Col lg={10} md={10} className=" d-flex p-2 align-items-center">
                                                                <Row>
                                                                    <Col lg={12}>
                                                                    <h3 style={{ backgroundColor: 'transparent' }}><b style={{color:'#219653 !important'}}>{data && data.employee[0].empName}</b> / {data && data.employee[0].empCode}</h3>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                    {data && data.question && data.question.map((item, index) =>(
                                        <Row className="p-2 mt-2" key={index}>
                                            <h5>{item.sectName}</h5>
                                            <Col>
                                                <ListGroup variant='flush' className="question-list">
                                                    {item.questions && item.questions.map((item, index2) =>(
                                                        <ListGroup.Item className='' key={index2}>
                                                            <Row className=''>
                                                                <Col className="d-flex align-items-center">{index2 + 1}. {item.questionText}</Col>

                                                                {item.questionType === 'rating' ? (
                                                                <Col lg={4} className="p-0 starrating risingstar d-flex justify-content-start flex-row-reverse ">
                                                                    <input onChange={(e) => handleInputChange(item.questionId, e.target.value) } type="radio" id={item.questionId + '_5'} name={item.questionId} defaultValue={5}  /><label htmlFor={item.questionId + '_5'} title="5 star"></label>
                                                                    <input onChange={(e) => handleInputChange(item.questionId, e.target.value) } type="radio" id={item.questionId + '_4'} name={item.questionId} defaultValue={4} /><label htmlFor={item.questionId + '_4'} title="4 star"></label>
                                                                    <input onChange={(e) => handleInputChange(item.questionId, e.target.value) } type="radio" id={item.questionId + '_3'} name={item.questionId} defaultValue={3} /><label htmlFor={item.questionId + '_3'} title="3 star"></label>
                                                                    <input onChange={(e) => handleInputChange(item.questionId, e.target.value) } type="radio" id={item.questionId + '_2'} name={item.questionId} defaultValue={2} /><label htmlFor={item.questionId + '_2'} title="2 star"></label>
                                                                    <input onChange={(e) => handleInputChange(item.questionId, e.target.value) } type="radio" id={item.questionId + '_1'} name={item.questionId} defaultValue={1} /><label htmlFor={item.questionId + '_1'} title="1 star"></label>
                                                                </Col>
                                                                ) : null
                                                                }

                                                                {item.questionType === 'free_score' ? (
                                                                <Col lg={4} className="p-0 d-flex justify-content-end">
                                                                    <Form.Control
                                                                        style={{maxWidth: '100px'}}
                                                                        type="text"
                                                                        name={item.questionId}
                                                                        onChange={(e) =>
                                                                            handleInputChange(item.questionId, e.target.value)
                                                                        }
                                                                        readOnly={isAnswer === 1} 
                                                                    />
                                                                </Col>
                                                                ) : null
                                                                }

                                                                {item.questionType === 'yes_no' ? (
                                                                <Col lg={4} className="p-0 d-flex justify-content-end">
                                                                    <Form.Check
                                                                        inline
                                                                        label="Yes"
                                                                        name={item.questionId}
                                                                        type="radio"
                                                                        value="1"
                                                                        onChange={(e) =>
                                                                            handleInputChange(item.questionId, e.target.value)
                                                                        }
                                                                        readOnly={isAnswer === 1}
                                                                    />
                                                                    <Form.Check
                                                                        inline
                                                                        label="No"
                                                                        name={item.questionId}
                                                                        type="radio"
                                                                        value="0"
                                                                        onChange={(e) =>
                                                                            handleInputChange(item.questionId, e.target.value)
                                                                        }
                                                                        readOnly={isAnswer === 1}
                                                                    />
                                                                </Col>
                                                                ) : null
                                                                }   
                                                            </Row>
                                                        </ListGroup.Item>
                                                    ))}
                                                </ListGroup>
                                            </Col>
                                        </Row>

                                    ))}

                                    <Row className='p-2'>
                                        <Col lg={12} className='d-flex justify-content-end'>
                                        <Button bsPrefix='btn' className="submit-evaluation" onClick={handleSubmit}>Submit</Button>{' '}
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        ) : (
                            <div className="" style={{ backgroundColor: "transparent" }} >
                                <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                                <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                                </div>
                            </div>
                        )}
                            
                    </Col>

                </Row>

            </Container>
        </>
  )
}

export default EvaluateQuestions