import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faChevronLeft, faChevronRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { getAuthData } from "../util/auth";
import { Link} from "react-router-dom";
import EventCourseList from "../components/EventCourseList";
import EventClassList from "../components/EventClassList";
import EventWebinarList from "../components/EventWebinarList";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function CalendarPage() {
    const currentDate = new Date();
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = currentDate.toLocaleDateString('id-ID', options);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isFetching, setIsFetching] = useState(false);
    const [fetchProgress, setFetchProgress] = useState(0);
    const [listDate, setListDate] = useState([]);

    const nest_url = process.env.REACT_APP_NEST_URL;

    const secretCode = process.env.REACT_APP_SECRET_CODE;
    const token = getAuthData();

    const headers = {
        Authorization: `Bearer ${token.jwt}`,
        secret: secretCode,
        'Content-Type': 'application/json',
    };

    if (!token) {
        window.location.href = '/auth';
    }

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    const monthNames = {
        January: 'Januari',
        February: 'Februari',
        March: 'Maret',
        April: 'April',
        May: 'Mei',
        June: 'Juni',
        July: 'Juli',
        August: 'Agustus',
        September: 'September',
        October: 'Oktober',
        November: 'November',
        December: 'Desember'
    };

    const [dateMonth, setDateMonth] = useState(getFormattedDate());

    const generateDates = (year, month) => {
        const dates = [];
        const options = { weekday: 'short', day: 'numeric' };

        for (let i = 1; i <= 31; i++) {
            const date = new Date(year, month - 1, i);
            if (date.getMonth() !== month - 1) break;

            const formattedDate = date.toLocaleDateString('id-ID', options);
            const dayOfWeek = formattedDate.slice(0, 3);
            const dayOfMonth = formattedDate.slice(formattedDate.indexOf(' ') + 1, formattedDate.indexOf(' ') + 3).trim();
            const monthName = months[date.getMonth()];
            const yearNumber = date.getFullYear();
            const formattedDateString = `${dayOfWeek}, ${dayOfMonth} ${monthName} ${yearNumber}`;
            dates.push(formattedDateString);
        }

        return dates;
    };

    const [dates, setDates] = useState(generateDates(currentDate.getFullYear(), currentDate.getMonth() + 1));

    function getFormattedDate() {
        const currentDate = new Date();
        const month = months[currentDate.getMonth()];
        const year = currentDate.getFullYear();
        return `${month} ${year}`;
    }

    function handlePrevMonth() {
        const [month, year] = dateMonth.split(' ');
        const monthIndex = months.indexOf(month) + 1;
        const prevMonthIndex = monthIndex > 1 ? monthIndex - 1 : 12;
        const prevMonth = months[prevMonthIndex - 1];
        const prevYear = prevMonthIndex === 12 ? parseInt(year, 10) - 1 : year;
        const newDates = generateDates(prevYear, prevMonthIndex);
        setDates(newDates);
        setDateMonth(`${prevMonth} ${prevYear}`);
        fetchDataDataIndicator("01 " + prevMonth + " " + prevYear);
    }

    function handleNextMonth() {
        const [month, year] = dateMonth.split(' ');
        const monthIndex = months.indexOf(month) + 1;
        const nextMonthIndex = monthIndex < 12 ? monthIndex + 1 : 1;
        const nextMonth = months[nextMonthIndex - 1];
        const nextYear = nextMonthIndex === 1 ? parseInt(year, 10) + 1 : year;
        const newDates = generateDates(nextYear, nextMonthIndex);
        setDates(newDates);
        setDateMonth(`${nextMonth} ${nextYear}`);
        fetchDataDataIndicator("01 " + nextMonth + " " + nextYear);
    }

    const current_date = currentDate.toLocaleDateString('id-ID', { weekday: 'short', day: 'numeric', month: 'long', year: 'numeric' });

    const [selectedDate, setSelectedDate] = useState(current_date);

    useEffect(() => {
        setIsFetching(true);
        setFetchProgress(0);
        // fetchDataDataIndicator("01 " + dateMonth);
        fetchData();
    }, [selectedDate]);

    const fetchData = async () => {
        try {
            let url = nest_url + "public/me/getEventsCalendar";

            const response = await fetch(url, {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    'date': selectedDate,
                }),
            });

            if (!response.ok) {
                throw new Error('Could not fetch data');
            }

            const responseBody = await response.json();
            setData(responseBody.data);

            setFetchProgress(100);
            setIsFetching(false);
        } catch (error) {
            setError(error);
            window.location.href = '/auth';
        }
    };

    const fetchDataDataIndicator = async (date) => {
        try {
            let url = nest_url + "public/me/getEventsCalendarDateList";

            const response = await fetch(url, {
                method: "POST",
                headers: headers,
                body: JSON.stringify({
                    'date': date
                }),
            });

            if (!response.ok) {
                throw new Error('Could not fetch data');
            }

            const responseBody = await response.json();

            // console.log(responseBody.data);
            setListDate(responseBody.data);

            setFetchProgress(100);
            setIsFetching(false);
        } catch (error) {
            setError(error);
            window.location.href = '/auth';
        }
    };


    const handleDateClick = (selectedDate) => {
        setSelectedDate(selectedDate);
    };


    const indonesianDays = ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'];
    const englishDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    // Convert Indonesian day labels to English
    const convertToEnglishDay = (dayLabel) => {
        const index = indonesianDays.indexOf(dayLabel);
        return index !== -1 ? englishDays[index] : dayLabel;
    };

    return (
        <>
            <Container fluid className="p-0 " >

                <Row className="">
                        <div className="d-flex justify-content-start pt-3 pb-2 mr-3">
                            <Link to={`/dashboard`} style={{ textDecoration: 'none' }}>
                                <h6>Dashboard</h6> 
                            </Link>
                        </div>
                </Row>

                <Row className="">
                    <Col>
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-1 pb-1 mb-0">
                            <h1 className="h4">Calendar</h1>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center pt-1 pb-1 mb-0">
                            <InputGroup className="" style={{ maxWidth: '250px' }}>
                                <Button variant="secondary" id="previousMonth" onClick={handlePrevMonth}>
                                    <FontAwesomeIcon icon={faChevronLeft} style={{ backgroundColor: 'transparent' }} />
                                </Button>
                                <Form.Control
                                    style={{ textAlign: 'center', color: '#0f4372', fontWeight: 'bold' }}
                                    aria-label="Example text with button addon"
                                    aria-describedby="basic-addon1"
                                    value={dateMonth}
                                    readOnly
                                    id="dateMonth"
                                />
                                <Button variant="secondary" id="nextMonth" onClick={handleNextMonth}>
                                    <FontAwesomeIcon icon={faChevronRight} style={{ backgroundColor: 'transparent' }} />
                                </Button>
                            </InputGroup>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-0">

                        </div>
                    </Col>
                </Row>

                <Row className="mt-2 mt-lg-2">
                    {dates ? (
                        <Card className="mb-4">
                            <Card.Body className="card-today-schedule d-flex justify-content-center">
                                <Col>
                                    <Row>
                                        <Col className="d-flex justify-content-center">
                                            <div>
                                                {dates.map((date, index) => {
                                                    const dayLabel = date.slice(0, 3);
                                                    const dateLabel = date.slice(date.indexOf(' ') + 1, date.indexOf(' ') + 3).trim();
                                                    const [dayNameAbbreviation, day, month, year] = date.split(' ');
                                                    const indonesianMonth = monthNames[month];
                                                    const formattedDate = `${dayNameAbbreviation} ${day} ${indonesianMonth} ${year}`;
                                                    const isCurrentDate = formattedDate === selectedDate;
                                                    const isHavingData = listDate[date.replace(/^\w+, /, "")];

                                                    return (
                                                        <div
                                                            className=" text-muted text-center"
                                                            style={{ display: 'inline-block', marginRight: '7px' }}
                                                            key={index}
                                                            onClick={() => handleDateClick(formattedDate)}
                                                        >
                                                            <label style={{ fontSize: '13px', marginBottom: '5px' }}>
                                                                <b>{convertToEnglishDay(dayLabel)}</b>
                                                            </label>
                                                            <br />
                                                            <label className={isCurrentDate ? 'current-date' : 'regular-date'}>
                                                                {dateLabel}
                                                            </label>

                                                            {isHavingData && (
                                                                <div className="dot d-flex text-center"></div>
                                                            )}

                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Card.Body>
                        </Card>
                    ) : (
                        <div className="" style={{ backgroundColor: "white" }} >
                            <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                            <Skeleton style={{ height: "200px", borderRadius: '15px' }} />
                            </div>
                        </div>
                    )}

                    <Card >
                        {data !== null ? (
                            <Card.Body className="card-today-schedule">
                                <Row>
                                    <Col lg={4}>
                                        <Card.Title

                                            style={{ fontSize: "17px", fontWeight: 'bold' }}
                                        >
                                            <FontAwesomeIcon icon={faBell} className="nav-icon-sidebar" /> Events
                                        </Card.Title>
                                    </Col>
                                    <Col lg={8} className="d-flex justify-content-end">
                                        <h5 className="text-danger">
                                            {selectedDate.replace(/^[A-Z][a-z]+,\s/, '')} <Badge bg="danger">{data !== null && data.total_data}</Badge>
                                        </h5>
                                    </Col>
                                </Row>

                                <div className="p-2 mt-0">
                                {data.class.length > 0 ? (
                                                data.class.map((classData, index) => (
                                                    <EventClassList key={index} params={classData} />
                                                ))
                                            ) : data.unstarted.length > 0 ? (
                                                data.unstarted.map((unstartedData, index) => (
                                                    <EventCourseList key={index} params={unstartedData} />
                                                ))
                                            ) : data.webinar.length > 0 ? (
                                                data.webinar.map((webinarData, index) => (
                                                    <EventWebinarList key={index} params={webinarData} />
                                                ))
                                            ) : null}

                                </div>

                            </Card.Body>
                        ) : (
                            <div className="" style={{ backgroundColor: "transparent" }} >
                                <div className="skeleton-wrapper" style={{ backgroundColor: "transparent" }}> 
                                <Skeleton style={{ height: "100px", borderRadius: '15px' }} />
                                </div>
                            </div>
                        )}
                        
                    </Card>
                </Row>

            </Container>
        </>

    );
}

export default CalendarPage;

