
import NavbarColibri from "../components/Navbar";
import ContainerFluid from "../components/ContainerFluid";
import { useLoaderData, useSubmit } from "react-router-dom";
import { useEffect, useState } from "react";
import jwt_decode from 'jwt-decode';
import NavSideBar from "../components/NavSideBar";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Image from "react-bootstrap/Image";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotificationItems from "../components/NotificationItems";
import OffCanvasSideMenu from '../components/OffCanvasSideMenu';
import { useSelector, useDispatch } from 'react-redux';

import {
    faBarsStaggered,
    faBurger,
    faSignOut, faUser
} from "@fortawesome/free-solid-svg-icons";
import CircleImageDropdown from '../components/CircleImageDropdown';
import { Button, Dropdown, Stack } from 'react-bootstrap';
import Notifications from '../components/Notifications';

import React from "react";
import Nav from "react-bootstrap/Nav";
import ListMenu from '../components/ListMenu';
import { Outlet } from "react-router-dom";
import { NavLink } from "react-bootstrap";
import { faLayerGroup, faThLarge } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../components/Sidebar";
import HeaderNotifications from "../components/HeaderNotifications";

function RootLayout() {
    const authData = useLoaderData();
    const submit = useSubmit();
    useEffect(() => {
        if (!authData) {
            return;
        }

        const decodedToken = jwt_decode(authData.jwt); // Decode the JWT to obtain the payload
        const expirationTime = decodedToken.exp; // Access the 'exp' key from the payload

        const currentTime = Math.floor(Date.now() / 1000); // Get the current timestamp
        const remainingTime = expirationTime - currentTime; // Calculate the remaining time until expiration

        setTimeout(() => {
            submit(null, { action: '/logout', method: 'post' });
        }, remainingTime * 1000);
    }, [authData, submit]);

    const notifTotal = "10";
    const handleLogout = () => {
        submit(null, { action: '/logout', method: 'post' });
    };

    
    const myProfile = () => {
        submit(null, { action: '/my-profile', method: 'get' });
    };

    const decodedToken = jwt_decode(authData.jwt); // Decode the JWT to obtain the payload
    
    const name    = useSelector((state) => state.user.empName);
    const empCode = useSelector((state) => state.user.empCode);
    const foto    = useSelector((state) => state.user.empPhoto);

    const [selectedComponent, setSelectedComponent] = useState('');

    const handleComponentChange = (component) => {
        setSelectedComponent(component);
    };

    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    const handleResize = () => {
        const newViewportWidth = window.innerWidth;
        console.clear();
        console.log('Viewport Width:', newViewportWidth);
        setViewportWidth(newViewportWidth);
        if (newViewportWidth <= 1080) {
          setSidebarOpen(false);
        } else {
          setSidebarOpen(true);
        }
      };
    
      useEffect(() => {
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
      

    return <>
        <div className="">
            {/* <NavbarColibri className="" /> */}
            {/* <ContainerFluid /> */}
            <Container fluid className='bg-light p-1 '>
                <Navbar expand="lg" className="bg-body-tertiary full-width-navbar sticky-top bg-light  navbar-light">
                    {/* <Container fluid className='bg-light p-1 border'> */}
                    <Col className="w-100 bg-light">
                        <Stack direction="horizontal" gap={3} className="bg-light">
                            <div className="p-0 bg-light text-center " style={{ width: '150px' }}>
                                <Navbar.Brand href="/" className="col-md-3 col-lg-2 me-0 p-0 fs-6 bg-light ">
                                    <Image src="/logo-informa.png" alt="Logo" className="colibri-logo-image bg-light" />
                                </Navbar.Brand>
                            </div>
                            <div className="p-2 bg-light d-none d-md-none d-lg-block">
                                {viewportWidth > 1080 && (
                                <Button className="toggle-sidebar-btn bg-light" variant="light" onClick={toggleSidebar}>
                                    <FontAwesomeIcon icon={faBarsStaggered} />
                                </Button>
                                )}
                            </div>
                            <div className="p-0 ms-auto">
                                <HeaderNotifications />
                            </div>
                            
                            <div className="p-0 d-block d-md-block">
                                <CircleImageDropdown
                                    imageSrc={foto}
                                    title={name}
                                    subtitle={empCode}
                                >
                                    <Dropdown.Item onClick={myProfile}>
                                        <FontAwesomeIcon icon={faUser} className='nav-icon-sidebar' /> My Profile
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={handleLogout}>
                                        <FontAwesomeIcon icon={faSignOut} className='nav-icon-sidebar' /> Logout
                                    </Dropdown.Item>
                                </CircleImageDropdown>
                            </div>

                            <OffCanvasSideMenu />
                        </Stack>
                    </Col>

                    {/* <Navbar.Brand href="/" className="navbar-brand col-md-3 col-lg-2 me-0 p-0 fs-6 bg-light ">
                            <Image src="/blacklogo-1.png" alt="Logo" className="colibri-logo-image" />
                    </Navbar.Brand>

                    <Navbar.Brand href="/" className="navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6 bg-light bu-logo">
                        <Image src="/logo-informa.png" alt="Logo" className="colibri-logo-image d-none d-md-none d-lg-block" />
                    </Navbar.Brand>

                    <div className="d-lg-none">
                        <Stack direction="horizontal" gap={1} style={{ backgroundColor: '#f8f9fa' }}>
                            <div className="p-0" style={{ backgroundColor: '#f8f9fa' }}>
                                <Navbar id="basic-navbar-nav-mid" className='justify-content-end bg-light '>
                                    <Notifications
                                        count={notifTotal}
                                    >
                                        <NotificationItems
                                            title="New Daily Quiz"
                                            subtitle="1 Jan 2023"
                                            badge="Daily Quiz"
                                            color="success"
                                        />
                                        <Dropdown.Divider />

                                        <NotificationItems
                                            title="New Daily Quiz"
                                            subtitle="1 Jan 2023"
                                            badge="Daily Quiz"
                                            color="warning"
                                        />
                                        <Dropdown.Divider />
                                    </Notifications>
                                </Navbar>
                            </div>
                            <div className="p-0" style={{ backgroundColor: '#f8f9fa' }}>
                                <OffCanvasSideMenu />
                            </div>
                        </Stack>
                    </div>

                    <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end bg-light d-none d-md-none d-lg-block'>
                        <Notifications
                            count={notifTotal}
                        >
                            <NotificationItems
                                title="New Daily Quiz"
                                subtitle="1 Jan 2023"
                                badge="Daily Quiz"
                                color="success"
                            />
                            <Dropdown.Divider />

                            <NotificationItems
                                title="New Daily Quiz"
                                subtitle="1 Jan 2023"
                                badge="Daily Quiz"
                                color="warning"
                            />
                            <Dropdown.Divider />
                        </Notifications>
                        <CircleImageDropdown
                            imageSrc={authData.foto}
                            title={name}
                            subtitle={empCode}
                        >
                            <Dropdown.Item>
                                <FontAwesomeIcon icon={faUser} className='nav-icon-sidebar' /> My Profile
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleLogout}>
                                <FontAwesomeIcon icon={faSignOut} className='nav-icon-sidebar' /> Logout
                            </Dropdown.Item>
                        </CircleImageDropdown>
                    </Navbar.Collapse> */}
                    {/* </Container> */}
                </Navbar>
            </Container>

            <Container fluid >
                <Row style={{ minHeight: '100vh' }}>
                    <Col>
                        {/* <NavSideBar /> */}
                        <Row className="" style={{ height: "100%" }} >
                            {window.innerWidth >= 1080 && (
                                <div 
                                className={`${isSidebarOpen ? "" : "d-none"} p-0`}
                                style={{ maxWidth: "180px"}}>
                                    <Col className=" p-0 d-flex flex-column flex-shrink-0" style={{height: '100%'}}>
                                        <Nav
                                            defaultActiveKey="/home"
                                            id="sidebarMenu"
                                            className={`bg-light sidebar p-0 ${isSidebarOpen ? "d-lg-block show" : "d-none hide"
                                                }`}
                                            style={{
                                                transition: "opacity 0.3s ease-in-out",
                                                opacity: isSidebarOpen ? 1 : 0,
                                                height: "100%",
                                                position: "relative"
                                            }}
                                        >
                                            <div className="d-none d-md-none d-lg-block  position-sticky sidebar-sticky p-0 bg-light">
                                                <ListMenu />
                                            </div>
                                            <div className="d-none d-md-none d-lg-block  w-100 text-center bg-light" style={{ position: "absolute", bottom: 0 }}>
                                                <Navbar.Brand href="/" className="col-md-3 col-lg-2 me-0 p-0 fs-6 bg-light">
                                                    <Image src="/blacklogo.png" alt="Logo" className="colibri-logo bg-light" />
                                                </Navbar.Brand>
                                            </div>
                                        </Nav>
                                    </Col>
                                </div>

                            )}
                            <Col className="flex-grow-1"
                                lg={isSidebarOpen ? "10" : "12"}
                                md={isSidebarOpen ? "10" : "12"}
                                style={{
                                    transition: "margin-left 0.3s ease-in-out",
                                    paddingRight: "0 !important",
                                }}
                            >


                                <Outlet />

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    </>;
}

export default RootLayout;